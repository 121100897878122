import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FormControl from "@mui/material/FormControl/FormControl";
import RenderMetadataField from "../../common/RenderMetadataField";
import PropTypes from "prop-types";
import React from "react";
import {withStyles} from "@mui/styles";

const styles = theme => ({});

function MetadataPanel(props) {

    //Render fields for each metadata item on the document
    const debug = window.location.pathname.toLowerCase().includes("debug");
    debug && console.log ('MetadataPanel props: ', props);
    const classes = props.classes;

    let metadataFields = props.metadataFields;

    //Need to get formValues for all fields for purpose of fields with options dependencies
    let formValues = {};
    if (metadataFields && metadataFields.length > 0){
        Object.entries(metadataFields).forEach(entry => {
            formValues[entry[1].templateKey + "~" + entry[1].metadataKey] = entry[1].value;
        });
    }

    let editableFields = [];

    const showMetadata = !props.isFetchingMetadata && !props.fetchError;


    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant={"h6"} color={"textPrimary"} className={classes.sidePanelHeader}>Metadata</Typography>
                {
                    props.isFetchingMetadata ?
                        <div className={classes.sidePanelHeader}><LinearProgress variant={"indeterminate"} color={"secondary"}/></div>:
                        props.fetchError &&
                        <Typography variant={"body2"} color={"textPrimary"} className={classes.sidePanelHeader}>
                            Error retrieving metadata
                        </Typography>
                }
            </Grid>
            {
                showMetadata && metadataFields && Array.isArray(metadataFields) && metadataFields.length > 0 &&
                <Grid item xs={12}>
                    <List>
                        {
                            metadataFields.map( field => {
                                editableFields.push(field.templateKey + "~" + field.metadataKey)
                                return(
                                    <ListItem key={field.templateKey + field.metadataKey}>
                                        <FormControl  variant="standard" fullWidth  style={{paddingBottom: '10px'}} key={"fc" + field.templateKey + "~" + field.metadataKey}>
                                            <RenderMetadataField
                                                fieldValue={field.value ? field.value : null}
                                                handleOnChange={props.handleOnChangeMetadata}
                                                metadataConfig={props.metadataConfig}
                                                optionsConfig={props.optionsConfig}
                                                metadataKey={field.metadataKey}
                                                templateKey={field.templateKey}
                                                forceDisable={props.isFetchingMetadata || !props.hasEditAccess || field.readOnly}
                                                usage={"edit"}
                                                formValues={formValues}
                                            />
                                        </FormControl>
                                    </ListItem>)
                            })
                        }
                    </List>
                </Grid>
            }
        </Grid>
    )
}

MetadataPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    isFetchingMetadata: PropTypes.bool.isRequired,
    fetchError: PropTypes.bool.isRequired,
    metadataFields: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    handleOnChangeMetadata: PropTypes.func.isRequired,
    hasEditAccess: PropTypes.bool.isRequired
};

export default (withStyles(styles, { withTheme: true} )(MetadataPanel));