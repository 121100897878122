import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import {Grid} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import {getErrorMessageFromResponse, hasAccess} from "../../common/helper"
import Preview from "./Preview";
import CommentsPanel from "../comments/CommentsPanel";
import MetadataPanel from "./MetadataPanel";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import { useConfirm } from "material-ui-confirm";

const styles = theme => ({

    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" }, // KEY CHANGES


    previewDialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },

    root: {
        // height: '90vh',
        // overflow: 'hidden',
        // flexGrow: 1,
    },

    container: {
        display: 'flex',
        overflow: 'hidden',
    },

    left: {
        float: 'left',
        width: '50%'
    },

    right: {
        float: 'right',
        width: '50%'
    },

    paper: {
        height: 140,
        width: 100,
    },

    sideMenu: {
        width: theme.spacing(8),
        height: '100%',
        visibility: 'visible',
        display: 'block',
        float: 'right',
        backgroundColor: theme.palette.secondary.main
    },

    sideMenuButton: {
        color: 'white',
        marginTop: theme.spacing(1)
    },

    sideMenuButtonActive: {
        color: theme.selectedTab.underlineColour + '!important', //theme.selectedTab.textColour,
        marginTop: theme.spacing(1)
    },

    sidePanelHeader: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing (.5)
    },
    //https://stackoverflow.com/questions/47698037/how-can-i-set-a-height-to-a-dialog-in-material-ui
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },

});

function PreviewDialog(props) {

    const confirm = useConfirm()

    const debug = window.location.pathname.toLowerCase().includes("debug");
    const [open, setOpen] = useState(true)
    const [doc, setDoc] = useState(null)
    const [metadataFields, setMetadataFields] = useState(props.documentMetadataFields)
    const [metadataHasChanged, setMetadataHasChanged] = useState(false)
    const [metadataUpdated, setMetadataUpdated] = useState(false)
    const [isFetchingMetadata, setIsFetchingMetadata] = useState(false)
    const [showSidePanel, setShowSidePanel] = useState(false)
    const [showSidePanelOption, setShowSidePanelOption] = useState(false) //todo pass in default?
    const [fetchError, setFetchError] = useState();

    useEffect(() => {

        console.log ('PreviewDialog props = ', props)

        if(props.showMetadataOnOpen) {
            clickShowSidePanel("metadata");
        }
        debug && console.log('boxDocId:', props.boxDocID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseDialog = (event, reason) => {

        if (!(reason && reason === "backdropClick")) {

            //clear state
            setOpen(false)
            setDoc(null)
            setMetadataFields({})
            setIsFetchingMetadata(false)
            setFetchError(false)
            setMetadataHasChanged(false)

            if (props.handleCloseDialog) {
                props.handleCloseDialog(metadataUpdated) //to trigger refresh of metadata in results table
            }
        }

    };

    const clickShowSidePanel = (option) => {

        setShowSidePanel(true)
        setShowSidePanelOption(option)

        switch (option) {
            case "metadata": {
                if (!doc ) {
                    //get metadata if not already retrieved
                    getMetadata();
                }
                break;
            }
            default: {
                break;
            }
        }
    };

    const hideSidepanel = () => {
        setShowSidePanel(false)
        setShowSidePanelOption("")
    }

    const getMetadata = async () => {

        debug && console.log ('====getMetadata=====');

        setIsFetchingMetadata(true)
        setFetchError(false)

        let getContent = true;

        //get list of templates from metadataConfig
        //&fields=metadata.scope.templateName
        debug && console.log ('metadataFields=' , metadataFields)
        //let templateNames = [];
        // Object.entries(props.metadataConfig).forEach(template => {
        //     if (template[0] !== "n/a") {
        //         if (templateNames.indexOf(template[0]) === -1) {
        //             templateNames.push(template[0])
        //         }
        //     }
        // })
        //Note, only list templates that are needed as metadata only being returned for first 3 templates listed
        const templateNames = [...new Set(metadataFields.map(item => item.templateKey))];

        debug && console.log ('templateNames=', templateNames);

        let fieldsStr = "name,created_at,modified_at,version,size";
        if (templateNames.length > 0) {
            const templateNamesStr = templateNames.map (t => "metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + t).toString();
            fieldsStr = fieldsStr + "," + templateNamesStr
        }

        const pathVar = "/" + props.boxDocID;
        //metadata=false still required but fields param used to get metadata
        const params =  "?userId=" + props.userDetails.boxId + "&content=" + getContent + "&metadata=false&fields=" + fieldsStr;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;

        const request = {
            headers: {"Authorization": "Bearer " + props.userDetails.accessToken}
        };

        debug && console.log ("getMetadata url :", url);

        await props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving metadata'))
                        .then(message => {
                            enqueueSnackbar(message , {variant: 'error'});
                            setFetchError(true)
                        })
                    debug && console.log("getMetadata error.  url:", url, "request: ", request, "response.text:", response);
                    return null

                }
            })
            .then(result => {

                debug && console.log('getMetadata, result=', result);

                setDoc(result)

                // this.setState({isFetchingMetadata: false});

                //extract metadata
                let updatedMetadataFields = JSON.parse(JSON.stringify(metadataFields));

                //get values
                if (updatedMetadataFields) {
                    for (let i = 0; i < updatedMetadataFields.length; i++) {
                        let templateKey = updatedMetadataFields[i].templateKey;
                        if (templateKey !== 'n/a') {
                            let metadataKey = updatedMetadataFields[i].metadataKey;
                            // listOfFields.push(templateKey + "~" + metadataKey);
                            if (result.metadata && 
                                result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID] && 
                                result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey] && 
                                result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey]) {
                                
                                updatedMetadataFields[i].value = result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey];
                            } else {
                                updatedMetadataFields[i].value = null;
                            }
                        }
                    }
                } 
                
                setMetadataFields(updatedMetadataFields);
                setIsFetchingMetadata (false);

            })
            .catch(e => {
                debug && console.log("getMetadata Exception:", e, " url:", url, "request: ", request);
                enqueueSnackbar("Error retrieving metadata - " + e, {variant: 'error'});
                setIsFetchingMetadata (false);
            })
    };

    const confirmClose = () => {

        confirm({
            title: "Close without saving?",
            description: "Are you sure you wish to close without saving?",
            confirmationText: "Save",
            confirmationButtonProps: { autoFocus: true,variant: "contained", color: "primary" },
            cancellationText: "Close without Saving",
            cancellationButtonProps: { autoFocus: true,variant: "contained", color: "secondary" }}
        )
            .then(() => {
                updateMetadata()
            })
            .catch(() => {
                handleCloseDialog()
            });
    }

    const updateMetadata = async () => {

        debug && console.log('PreviewDialog submitRequest');

        let body = {
            //content: content,
            fileName: doc.name,
            metadata: metadataFields
        };

        debug && console.log ("body: " , body);

        const pathVar = "/" + props.boxDocID;
        const params = '?audit=' + props.actionsConfig.edit.audit;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params;

        const request = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        debug && console.log ('submitRequest url:' , url, 'BODY:', body, 'request:' , request);

        setIsFetchingMetadata(true)

        await props.triggerRefreshAuthToken();
        fetch(url, request )
            .then(response => {
                if (response.ok) {
                    enqueueSnackbar("Metadata on " + body.fileName + " successfully updated.", {variant: 'success'});
                    setIsFetchingMetadata(false)
                    setMetadataHasChanged(false)
                    setMetadataUpdated(true)
                    //handleCloseDialog(true);
                } else {

                    Promise.resolve(getErrorMessageFromResponse(response, 'updating data'))
                        .then(message => {
                            enqueueSnackbar(message , {variant: 'error'});
                        })
                    debug && console.log("submitRequest error.  url:", url, "request: ", request, "response.text:", response.statusText, {variant: 'error'});
                    setIsFetchingMetadata(false)
                }
            })
            .catch(e => {
                debug && console.log("submitRequest Exception:", e, "url:", url, "request: ", request);
                setIsFetchingMetadata(false)
                enqueueSnackbar("Error " + e, {variant: 'error'});
            })
    };


    const handleOnChangeMetadata = (id, newValue) => {

        console.log ('handleOnChangeMetadata', id, newValue)

        let templateKey = id.split("~")[0];
        let metadataKey = id.split("~")[1];

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        //Update value on metadataFields object used to display the metadata fields
        //let newMetadataFields = metadataFields;        //need this temporary object to ensure all values are maintained
        let newMetadataFields = JSON.parse(JSON.stringify(metadataFields));
        //TODO need to deep clone?
        for (let i = 0; i < newMetadataFields.length; i++) {
            if (newMetadataFields[i].metadataKey === metadataKey && newMetadataFields[i].templateKey === templateKey) {
                newMetadataFields[i].value = val;
                break;
            }
        }
        setMetadataFields(newMetadataFields)

        if (!metadataHasChanged) {
            setMetadataHasChanged(true)
        }
    };

    


    const classes = props.classes;

    let fileOptions = {};
    fileOptions[props.boxDocID] = {
        startAt: {
            unit: 'pages',
            value: props.pageNumber
        }
    };

    const hasEditAccess = hasAccess(props.actionsConfig.edit, props.userDetails.userRoles);

    let enableCustomMetadata = false;
    if (props.actionsConfig.previewDocument.customMetadata) {
        if (props.actionsConfig.previewDocument.customMetadata.enabled) {
            if (hasAccess(props.actionsConfig.previewDocument.customMetadata, props.userDetails.userRoles)) {
                enableCustomMetadata = true
            }
        }
    }

    let enableComments = false;
    const commentsAction = props.actionsConfig.comments;
    if (commentsAction && commentsAction.enabled && hasAccess(commentsAction, props.userDetails.userRoles)) {
        enableComments = true
    }


    const showMenuBar = enableCustomMetadata || enableComments;



    window.location.pathname.toLowerCase().includes("debug") && console.log('showHeader: ', props.actionsConfig.previewDocument.showHeader);

    !metadataFields && console.log ('*** No metadata fields configured')

    return (
        <Dialog
            // classes={{ paper: classes.dialogPaper }}
            //classes={{ paper: classes.previewDialogPaper }} //works
            classes={{ paper: {
                    minHeight: '100vh',
                    maxHeight: '100vh',
                } }}

            open={open}
            //onClose={(event, reason) => handleCloseDialog(event, reason, false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="xl"
            // style={{height: '90vh'}}
            //height="90vh"
        >
            <Tooltip title={"Close"}>
                <IconButton
                    aria-label="close"
                    onClick={(event, reason) => metadataHasChanged ? confirmClose(false) : handleCloseDialog(event, reason)}
                    color={"secondary"}
                    sx={{position: 'absolute', right: 0, top: 0,}}
                >
                    <CloseIcon />
            </IconButton>
            </Tooltip>

            <DialogContent style={{
                overflow: 'hidden', //to prevent scroll bar appearing for container, want it to appear for each grid item instead
                display: 'inline-flex', //*** NB *** ....adding this fixed problem with Grid expanding beyond contents of dialogContent
                }}
                sx={(theme)=>(
                    {paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4), paddingTop: theme.spacing(4)
                })}
            >
                <Box noValidate
                     sx={{
                        flexBasis: 'auto', flexGrow: 1, flexShrink: 1,
                        overflowX: 'hidden', overflowY: 'hidden'
                }}
                >

                    <React.Fragment>
                        {/*<div style={{width: '100%', height: "90vh"}}>*/}
                        {/*<div style={{width: '100%', height: "100%", display: 'flex'}}>*/}
                        {/*    <div style={{width: '100%' , height: "100%", display: 'flex', float: 'left'}}>*/}
                            <Grid container spacing={0}
                                  style={ {maxHeight: "100%",  height: "90vh", overflowY: "auto"}} //to get grid height to expand to hold contents
                                   // style={{height: "90vh", overflow: "none"}}
                                  //style={ {height: "100%"}}
                            >

                {/*
               https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
               By default, a grid item cannot be smaller than the size of its content.
               Grid items have an initial size of min-width: auto and min-height: auto.
               You can override this behavior by setting grid items to
                   min-width: 0, min-height: 0 or overflow with any value other than visible.
                                */}
                                {/*Preview **************************/}
                                <Grid item xs={showSidePanel ? 8 : (showMenuBar ? 11 : 12)}
                                      style={{height: "100%", overflowY: "auto"}}
                                >
                                    {/*<IntlProvider locale="en">*/}
                                        <Preview boxDocID={props.boxDocID}
                                                 fileContent={doc ? doc.content : ""}
                                                 userDetails={props.userDetails}
                                                 showHeader={props.actionsConfig.previewDocument.showHeader}
                                                 actionsConfig={props.actionsConfig}
                                                 showSidebar={true}
                                                 triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                        />
                                    {/*</IntlProvider>*/}
                                </Grid>

                                {/*Side Menu Bar **********************/}
                                {
                                    showMenuBar &&
                                    <Grid item xs={1} style={{height: "100%", overflowY: "auto", textAlign: 'center'}}>
                                        <div className={classes.sideMenu}>
                                            <React.Fragment>
                                                {
                                                    showSidePanel &&
                                                    <Tooltip title={"Close sidepanel"}>
                                                        <IconButton
                                                            onClick={hideSidepanel}
                                                            className={classes.sideMenuButton}
                                                            size="medium">
                                                            <i className='material-icons'>chevron_right</i>
                                                        </IconButton>
                                                    </Tooltip>

                                                }
                                                {
                                                    enableCustomMetadata &&
                                                    <Tooltip title={"Show Metadata"}>
                                                        <IconButton
                                                            onClick={() => clickShowSidePanel("metadata")}
                                                            className ={showSidePanelOption === "metadata" ? classes.sideMenuButtonActive : classes.sideMenuButton}
                                                            disabled = {showSidePanelOption === "metadata"}
                                                            size="medium">
                                                            <i className="material-icons">info_outline</i>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {
                                                    enableComments &&
                                                    <Tooltip title={"Show Comments"}>
                                                        <IconButton
                                                            onClick={() => clickShowSidePanel("comments")}
                                                            className={showSidePanelOption === "comments" ? classes.sideMenuButtonActive : classes.sideMenuButton}
                                                            disabled = {showSidePanelOption === "comments"}
                                                            size="medium">
                                                            <i className="material-icons">comment</i>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </React.Fragment>
                                        </div>

                                    </Grid>
                                }

                                {/*Side Panel ****************************/}
                                {
                                    showSidePanel &&
                                    <Grid item xs={3} style={{height: "100%", overflowY: "auto"}}>
                                        {
                                            showSidePanelOption === "metadata" ?
                                            <MetadataPanel
                                                classes={classes}
                                                isFetchingMetadata={isFetchingMetadata}
                                                fetchError={fetchError}
                                                metadataFields={metadataFields}
                                                metadataConfig={props.metadataConfig}
                                                optionsConfig={props.optionsConfig}
                                                handleOnChangeMetadata={handleOnChangeMetadata}
                                                handleOnChangeMetadataDate={handleOnChangeMetadata}
                                                hasEditAccess={hasEditAccess}
                                            /> :
                                                showSidePanelOption === "comments" &&
                                                <CommentsPanel
                                                    parentClasses={classes}
                                                    boxDocID={props.boxDocID}
                                                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                                    userDetails={props.userDetails}
                                                    // enableAddComment={}
                                                    actionsConfig={props.actionsConfig}
                                                    handleCloseDialog={handleCloseDialog}/>
                                        }
                                    </Grid>
                                }
                            </Grid>
                        {/*</div>*/}
                    </React.Fragment>
                </Box>
            </DialogContent>

            <DialogActions>
                {/*<Button variant="contained" onClick={() => handleCloseDialog(false)} color="secondary">Close</Button>*/}
                <Button variant="contained"
                        onClick={(event, reason) => metadataHasChanged ? confirmClose(false) : handleCloseDialog(event, reason, false)}
                        // onClick={() => metadataHasChanged ? confirmClose(false) : handleCloseDialog}
                        color="secondary">
                    Close
                </Button>
                {
                    (hasEditAccess && metadataHasChanged) &&
                    <Button onClick={updateMetadata}
                            variant = "contained"
                            color = "primary"
                            disabled={isFetchingMetadata || !metadataHasChanged}>
                        {isFetchingMetadata ? "Updating..." : "Save"}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

PreviewDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired, // so that we can get the workspace details when a document is returned via Search All or when document has a different workspace property value to the selected workspace
    actionsConfig: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    //showMetadataOnOpen: PropTypes.bool,
    documentDetails: PropTypes.object.isRequired, //used by commentsPanel
    documentMetadataFields: PropTypes.array.isRequired
};

export default (withStyles(styles, { withTheme: true} )(PreviewDialog));