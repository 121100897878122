import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import PreviewDialog from "./PreviewDialog";

const styles = (theme) => ({});

const PreviewButton = (props) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleClickIcon = (event) => {
        setShowDialog(true);
    };

    const handleCloseDialog = (metadataUpdated) => {
        window.location.pathname.toLowerCase().includes("debug") &&
        console.log('handleCloseDialog metadataUpdated= ', metadataUpdated);

        setShowDialog(false);

        // Force unmount to refresh folder documents if metadata has changed
        // TODO test it works with paging....
        if (props.refreshAfterEditMetadata && metadataUpdated) {
            if (props.unmountFolderDocumentsGet) {
                props.unmountFolderDocumentsGet();
            }
        }
    };

    return (
        <div>
            <Tooltip title={props.useEditIcon ? "Edit Metadata" : "Open"}>
                <IconButton
                    aria-label="Menu"
                    aria-haspopup="true"
                    onClick={handleClickIcon}
                    color="secondary"
                    size="medium"
                >
                    <i className="material-icons">
                        {props.useEditIcon ? "edit" : "open_in_new"}
                    </i>
                </IconButton>
            </Tooltip>

            {showDialog && (
                <PreviewDialog
                    parentClasses={props.classes}
                    boxDocID={props.boxDocID}
                    userDetails={props.userDetails}
                    handleCloseDialog={handleCloseDialog}
                    calledFromDocumentMenu={true}
                    metadataConfig={props.metadataConfig}
                    optionsConfig={props.optionsConfig}
                    workspaceConfig={props.workspaceConfig}
                    selectedWorkspaceConfig={props.selectedWorkspaceConfig}
                    actionsConfig={props.actionsConfig}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    showMetadataOnOpen={props.showMetadataOnOpen}
                    documentDetails={props.documentDetails}
                    documentMetadataFields={props.documentMetadataFields}
                />
            )}
        </div>
    );
};

PreviewButton.propTypes = {
    classes: PropTypes.object.isRequired,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    unmountFolderDocumentsGet: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    showMetadataOnOpen: PropTypes.bool,
    useEditIcon: PropTypes.bool,
    refreshAfterEditMetadata: PropTypes.bool,
    documentDetails: PropTypes.object.isRequired, // used by commentsPanel
    documentMetadataFields: PropTypes.array.isRequired
};

export default withStyles(styles)(PreviewButton);
