import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import BottomNavigation from "@mui/material/BottomNavigation";
import Typography from '@mui/material/Typography';
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton/IconButton";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import {numberWithCommas} from "../../common/helper";
import Box from '@mui/system/Box';

const styles = theme => ({});

function InfiniteScrollLoader (props) {

    window.location.pathname.toLowerCase().includes("debug") && console.log ('InfiniteScrollLoader props=', props);

    let fetchingText = "Retrieving next " + props.searchLimit + " search results...";
    if ( props.searchReturnedCount + props.searchLimit >= props.searchTotalCount) {
        const remaining = props.searchTotalCount - props.searchReturnedCount;
        fetchingText = "Retrieving remaining " + numberWithCommas(remaining) ;
        if (remaining === 1) {
            fetchingText = fetchingText + " search result..."
        } else {
            fetchingText = fetchingText + " search results..."
        }
    }

    let actionComponents = [];
    actionComponents.push(
        <Tooltip title={"Show more"} key={"footerTT"}>
            <IconButton onClick={props.getNextResults} size={'small'} key={"ibNext"}>
                <i className='material-icons' key={"iNext"}>play_arrow</i>
            </IconButton>
        </Tooltip>
    );

    // actionComponents.push(
    //     <Tooltip title={"Show all"}>
    //         <IconButton onClick={props.getAllResults} size={'small'}>
    //             <i className='material-icons'>skip_next</i>
    //         </IconButton>
    //     </Tooltip>
    // );

    return (
        <Box sx={(theme)=>({
                paddingLeft: theme.spacing(2)
            })}
        >
            {
                props.isFetching &&
                <React.Fragment>
                    <LinearProgress style={{marginTop: '0px'}} color="primary"/>
                </React.Fragment>
            }

            {
                props.nextMarker || props.searchTotalCount === 0 ?

                    //Results from Metadata API where more docs (nextMarker) are available but we don't know total count

                    <BottomNavigation style={{justifyContent: 'left', paddingTop: '7px', height: '100%'}} showlabel="">
                        {/* 'default', 'error', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary'*/}
                        <Typography variant={"subtitle2"} color={'secondary'} style={{fontWeight: "normal"}}>
                            {
                                props.isFetching ?
                                    "Retrieving more results..." :
                                    props.nextMarker ?
                                        "Showing first " + props.searchReturnedCount + " search results" :
                                        props.searchReturnedCount === 1 ?
                                            "Showing all search results" :
                                            "Showing all " + props.searchReturnedCount + " search results"
                            }
                            {
                                (props.nextMarker && !props.isFetching)  ?
                                    actionComponents :
                                    ""
                            }
                        </Typography>
                    </BottomNavigation> :

                    //Case Search Results, Simple Search Results, Advanced Search Results

                    <BottomNavigation style={{justifyContent: 'left', paddingTop: '7px', height: '100%'}} showlabel="">
                        {/* 'default', 'error', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary'*/}
                        {/*React does not recognize the `showLabel` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `showlabel` instead*/}
                        <Typography variant={"subtitle2"} color={'secondary'} style={{fontWeight: "normal"}}>
                            {
                                props.isFetching ?
                                    fetchingText :
                                    "Showing " + props.searchReturnedCount + " of " + props.searchTotalCount + " search results"
                            }
                            {
                                (props.searchTotalCount > props.searchReturnedCount && !props.isFetching) ?
                                    actionComponents : ""
                            }
                        </Typography>
                    </BottomNavigation>
            }
        </Box>
    );

}

InfiniteScrollLoader.propTypes = {
    classes: PropTypes.object.isRequired,
    searchReturnedCount: PropTypes.number.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    nextMarker: PropTypes.string
};

export default (withStyles(styles, { withTheme: true })(InfiniteScrollLoader));

