import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import AddDocDialog from "./AddDocDialog";
import IconButton from "@mui/material/IconButton/IconButton";
import Tooltip from '@mui/material/Tooltip';
import AddDocOptionsDialog from "./AddDocOptionsDialog";
import SelectTemplateDialog from "./template/SelectTemplateDialog";

const styles = theme => ({

    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
});


function AddDocButton(props) {

    //const [open, setOpen] = useState(false)
    const [showOptionsDialog, setShowOptionsDialog] = useState()
    const [showAddDocDialog, setShowAddDocDialog] = useState()
    const [showSelectTemplateDialog, setShowSelectTemplateDialog] = useState()
    const [optionSelection, setOptionSelection] = useState()

    const addClick = () => {

        //if templateConfig set on folder then prompt to select template
        let showTemplateOption = false;
        if (props.folderDetails) {
            if (props.folderDetails.templateConfig) {
                if (props.folderDetails.templateConfig !== "") {
                    showTemplateOption = true
                }
            }
        }

        //if adding doc from main search results table then get templateConfig from addDocumentMain action config rather than folder details
        if (props.actionConfig && props.actionConfig.templateConfig) {
            // if (props.actionConfig.templateConfig !== "" ){
            if (Array.isArray(props.actionConfig.templateConfig) && props.actionConfig.templateConfig.length > 0 ){
                showTemplateOption = true
            }
        }

        showTemplateOption ? showAddDocOptionsDialog() : showDialog()

    };

    const showAddDocOptionsDialog = () => {
        setShowOptionsDialog(true)
    };

    const closeAddDocOptionsDialog = (selection) => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        setShowOptionsDialog(false);
        setShowAddDocDialog(selection === "upload")
        setShowSelectTemplateDialog(selection !== "" && selection !== "upload")
        setOptionSelection(selection)
    };

    const showDialog = () => {
        setShowAddDocDialog(true)
    };

    const closeAddDocDialog = () => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        setShowAddDocDialog(false)

        //force unmount to refresh folder documents
        if (props.unmountFolderDocumentsGet) {
            props.unmountFolderDocumentsGet();
        }

        if (props.uploadConfig.refreshSearchResultsAfterUpload && props.reloadWorkspace) {
            props.reloadWorkspace(props.selectedWorkspaceConfig)
        }
    };

    const closeSelectTemplateDialog = (filesAdded) => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        setShowSelectTemplateDialog(false)

        //force unmount to refresh folder documents
        if (filesAdded) {
            if (props.unmountFolderDocumentsGet) {
                props.unmountFolderDocumentsGet();
            }
        }
    };

    const cancelAddDocDialog = () => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        setShowAddDocDialog(false)
    };


    const { classes, uploadConfig, ...other } = props;

    let templateOptions = [];
    //read from folder metadata if Adding document within a Case
    if (props.folderDetails && props.folderDetails.templateConfig) {
        templateOptions = props.folderDetails.templateConfig.split(",")
    } else if (props.actionConfig && props.actionConfig.templateConfig) {
        templateOptions = props.actionConfig.templateConfig
    }


    let tooltipTitle = props.actionConfig.label ? props.actionConfig.label : "Add File(s)";
    if (props.folderDetails) {
        if (Object.entries(props.folderDetails).length > 0) {
            if (props.folderDetails.name && props.folderDetails.name !== "") {
                tooltipTitle = tooltipTitle + " to " + props.folderDetails.name
            }
        }
    }

    let showUploadOption = true;

    if (uploadConfig.uploadFromTemplateOnly) {
        showUploadOption = false
    }
    if (uploadConfig.uploadFromTemplateOnlyWhen) {
        const templateKey = uploadConfig.uploadFromTemplateOnlyWhen.templateKey;
        const metadataKey = uploadConfig.uploadFromTemplateOnlyWhen.metadataKey;
        const value = uploadConfig.uploadFromTemplateOnlyWhen.value;
        const folderDetails = props.folderDetails;
        if (folderDetails && folderDetails.metadata && folderDetails.metadata["enterprise_"+ window.REACT_APP_ENTERPRISE_ID]) {
            const folderMetadata = folderDetails.metadata["enterprise_"+ window.REACT_APP_ENTERPRISE_ID]
            if (folderMetadata[templateKey]) {
                if (folderMetadata[templateKey][metadataKey]) {
                    if(folderMetadata[templateKey][metadataKey] === value) {
                        showUploadOption = false
                    }
                }
            }
        }
    }

    const dialogTitle = uploadConfig.dialogTitle ? uploadConfig.dialogTitle : "Add document";

    const folderSearchConfig = props.selectedWorkspaceConfig.searchConfig.folderSearch;
    const indexes = folderSearchConfig && folderSearchConfig.elastic && folderSearchConfig.elastic.indexes;

    return (
        <React.Fragment>
            <Tooltip title={tooltipTitle}>
                <IconButton
                    color="secondary"
                    aria-label={dialogTitle}
                    onClick={addClick}
                    size="medium">
                    <i className={'material-icons md-36 ' + props.iconClass}>add</i>
                </IconButton>
            </Tooltip>

            {showOptionsDialog &&
            <AddDocOptionsDialog
                {...other}
                parentClasses={props.classes}
                userDetails={props.userDetails}
                handleCloseDialog={closeAddDocOptionsDialog}
                templatesConfig={props.templatesConfig}
                showUploadOption = {showUploadOption}
                templateOptions={templateOptions}
                uploadConfig={uploadConfig}
            />}

            {showAddDocDialog &&
                <AddDocDialog
                    {...other}
                    parentClasses={props.classes}
                    userDetails={props.userDetails}
                    uploadConfig={uploadConfig}
                    metadataConfig={props.metadataConfig}
                    optionsConfig={props.optionsConfig}
                    closeAddDocDialog={closeAddDocDialog}
                    cancelAddDocDialog={cancelAddDocDialog}
                    folderDetails = {props.folderDetails}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    actionConfig={props.actionConfig}
                    indexes={indexes}
                />}

            {showSelectTemplateDialog &&
            <SelectTemplateDialog
                {...other}
                parentClasses={props.classes}
                userDetails={props.userDetails}
                uploadConfig={uploadConfig}
                handleCloseDialog={closeSelectTemplateDialog}
                //folderDetails = {props.folderDetails}
                destinationFolderId={props.folderDetails ? props.folderDetails.id : props.selectedWorkspaceConfig.uploadConfig.folderId}
                // parentFolderId={props.folderDetails ? props.folderDetails.parentFolderId: ""} //only required when adding to a subfolder
                parentFolderId={
                    props.folderDetails ?
                        props.folderDetails.parentFolderId ?
                            props.folderDetails.parentFolderId: // adding to subfolder
                            props.folderDetails.id : //adding to case folder
                        ""}
                actionConfig={props.actionConfig}
                workspaceConfig={props.workspaceConfig}
                selectedWorkspaceConfig={props.selectedWorkspaceConfig}
                templateConfig={props.templatesConfig[optionSelection]}
                optionsConfig={props.optionsConfig}
            />}
        </React.Fragment>
    );
}

AddDocButton.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    uploadConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object,
    iconClass: PropTypes.string.isRequired,
    unmountFolderDocumentsGet: PropTypes.func,
    reloadWorkspace: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    templatesConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    indexes: PropTypes.array //required when folder source is elastic
};


export default (withStyles(styles, { withTheme: true })(AddDocButton));
